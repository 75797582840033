import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.boundSetIndicators = this.setIndicators.bind(this)
    this.element.addEventListener('scroll', this.boundSetIndicators)
    this.setIndicators();
  }

  setIndicators() {
    const parent = this.element.parentNode;
    parent.classList.add('hasScrollLeft', 'hasScrollRight');

    if (this.element.offsetWidth + this.element.scrollLeft >= (this.element.scrollWidth - 16)) {
      parent.classList.remove('hasScrollRight');
    }
    if (this.element.scrollLeft === 0) {
      parent.classList.remove('hasScrollLeft');
    }
  }

  disconnect() {
    this.element.removeEventListener('scroll', this.boundSetIndicators)
  }

}