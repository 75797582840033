import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['section', 'navlink', 'tab', 'tabPane'];
  timerId;

  connect() {
    this.boundHandleScroll = this.handleScroll.bind(this);
    window.addEventListener('scroll', this.boundHandleScroll);
    this.handleScroll();
  }

  disconnect() {
    window.removeEventListener('scroll', this.boundHandleScroll);
  }

  handleScroll() {
    let currentId;
    let currentTab;

    this.sectionTargets.forEach(section => {
      if (window.scrollY >= section.offsetTop - 165)
        currentId = section.getAttribute('id');
    });

    this.tabPaneTargets.forEach(tabPane => {
      if (window.scrollY >= tabPane.offsetTop - 165)
        currentTab = tabPane.getAttribute('id');
    });

    this.tabTargets.forEach(tab => {
      const isActive = tab.getAttribute('href') === '#' + currentTab;
      tab.classList.toggle('active', isActive);
    });

    // clearTimeout(this.timerId);
    this.navlinkTargets.forEach(navlink => {
      const isActive = navlink.getAttribute('href') === '#' + currentId;
      navlink.classList.toggle('active', isActive);

      // if (isActive) (navlink.scrollIntoViewIfNeeded ?? navlink.scrollIntoView)({ block: 'nearest', behavior: 'smooth' });
      if (isActive) {
        const x = navlink.offsetLeft; // pill x
        const w = navlink.offsetWidth; // pill w
        const px = navlink.parentNode.scrollLeft; // parent scroll x
        const pw = navlink.parentNode.offsetWidth; // parent width

        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
          let scrollX = x < px ? x : x + w - pw;
          navlink.parentNode.scroll({ left: scrollX, behavior: 'smooth' });
        }, 50);
      }
    });
  }
}
